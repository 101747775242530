<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <div class="row">
            <div class="col-md-4" *ngFor="let obj of arrPhoto">
                <img [src]="obj.photo"
                    style="width: 100%; object-fit: cover; height: 15rem; box-shadow: 6px 4px 3px #c3c3c317; padding: 5px;">
            </div>
        </div>
    </div>
</div>