<footer class="site-footer text-uppercase">
    <div class="footer-top" style="background-image:url(images/pattern/pt15.png);">
        <div class="container">
            <div class="row">
                <div class="col-md-5 col-12 col-xl-5 col-lg-5 col-sm-6 footer-col-4">
                    <div class="widget widget_services border-0">
                        <div style="display: flex;justify-content: center;">
                            <img src="../../assets/images/pote_Nursing_logo.png" style="width: 100px;" alt="">
                        </div>
                        <div style="text-align: center;line-height: 1.7rem;margin-top: 1rem;">
                            <p>
                                <span style="color: white;">
                                    P. R. Pote Patil Education & Welfare Trust's
                                </span> <br>
                                <span style="color: lightcoral;">
                                    P. R. Pote Patil College of Nursing, Amravati.
                                </span> <br>
                                <span style="color: bisque;">
                                    Recognized by Maharashtra Nursing Council (MNC) & Indian Nursing Council (INC), Delhi  and affiliated to MUHS, Nashik
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-12 col-xl-4 col-lg-4 col-sm-6 footer-col-4">
                    <div class="widget widget_services border-0">
                        <h5 class="m-b30 text-white">CONTACT US</h5>
                        <hr>
                        <div style="line-height: 2rem;">
                            <p style="text-transform: capitalize !important;">
                                Gajanan Township Road, <br>
                                Pote Estate, Amravati 444602, <br>
                                Maharashtra, India. <br>
                                Phone : 0721-2970465/ +91 9422264498 <br>
                                Email : prpNursingcollege@gmail.com <br>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-xl-3 col-lg-3 col-sm-6 footer-col-3 ">
                    <div class="widget widget_services border-0">
                        <h5 class="m-b30 text-white">Useful Link</h5>
                        <hr>
                        <ul>
                            <li><i class="ion-ios-arrow-right"></i> 
                                <a target="_blank" href="https://www.muhs.ac.in/">MUHS</a>
                            </li>
                            <li><i class="ion-ios-arrow-right"></i> 
                                <a target="_blank" href="">Indian Nursing Council</a>
                            </li>
                            <li><i class="ion-ios-arrow-right"></i> 
                                <a target="_blank" href="https://www.indiannursingcouncil.org/">Maharashtra Nursing Council</a>
                            </li>
                            <li><i class="ion-ios-arrow-right"></i> 
                                <a target="_blank" href="https://www.msbnpe.org/">MSBNPE</a>
                            </li>
                            <li><i class="ion-ios-arrow-right"></i> 
                                <a target="_blank" href="https://www.med-edu.in/">DMER</a>
                            </li>
                            <li><i class="ion-ios-arrow-right"></i> 
                                <a target="_blank" href="https://www.maharashtra.gov.in/">Government of Maharashtra </a>
                            </li>
                            <li><i class="ion-ios-arrow-right"></i> 
                                <a target="_blank" href="https://aishe.gov.in/aishe/home">AISHE</a>
                            </li>
                            <li><i class="ion-ios-arrow-right"></i> 
                                <a target="_blank">Anti Ragging</a>
                            </li>
                            <li><i class="ion-ios-arrow-right"></i> 
                                <a target="_blank" href="https://jdheamravati.org/">JDHE Amravati</a>
                            </li>
                            <li><i class="ion-ios-arrow-right"></i> 
                                <a target="_blank" href="https://www.ugc.gov.in/">UGC</a>
                            </li>
                            <li><i class="ion-ios-arrow-right"></i> 
                                <a target="_blank" href="http://naac.gov.in/index.php/en/">NAAC</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- footer bottom part -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-sm-4 text-left "> <span>Copyright © 2023 PRPP College of Nursing</span> </div>
                <div class="col-md-4 col-sm-4 text-right ">
                    <span>Made with <span style="color: red;">&hearts;</span> in India</span>
                </div>
                <div class="col-md-4 col-sm-4 text-right ">
                    <span>design & developed by <a href="http://logixspire.com" target="_blank">LogixSpire</a></span>
                </div>
            </div>
        </div>
    </div>
</footer>