<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <div id="comments-1" class="dlab-instructor">
            <ol class="commentlist">
                <li class="comment photo_staff">
                    <div class="comment_container"> <img class="avatar avatar-60 photo" src="../../assets/images/staff/1.jpg"
                            alt="">
                        <div class="comment-text">
                            <h6 style="font-size: 15px;" class="author m-t0">
                                Prof. Sudnayan G. Gawaie
                            </h6>
                            <div class="w3-separator bg-primary"></div>
                            <div class="description m-b15">
                                <p>
                                    Assistant Professor
                                </p>
                            </div>
                            <div class="clearfix m-b20">
                                <a target="_blank" href="../../assets/images/staff/1.pdf"
                                    class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="comment_container"> <img class="avatar avatar-60 photo" src="../../assets/images/staff/2.png"
                            alt="">
                        <div class="comment-text">
                            <h6 style="font-size: 15px;" class="author m-t0">
                                Miss. Devyani P. Padghan
                            </h6>
                            <div class="w3-separator bg-primary"></div>
                            <div class="description m-b15">
                                <p>
                                    Clinical Instructor
                                </p>
                            </div>
                            <div class="clearfix m-b20">
                                <a target="_blank" href="../../assets/images/staff/2.pdf"
                                    class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="comment_container"> <img class="avatar avatar-60 photo" src="../../assets/images/staff/3.jpg"
                            alt="">
                        <div class="comment-text">
                            <h6 style="font-size: 15px;" class="author m-t0">
                                Prof. Kapil K. Raibole
                            </h6>
                            <div class="w3-separator bg-primary"></div>
                            <div class="description m-b15">
                                <p>
                                    Assistant Professor
                                </p>
                            </div>
                            <div class="clearfix m-b20">
                                <a target="_blank" href="../../assets/images/staff/3.pdf"
                                    class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="comment_container"> <img class="avatar avatar-60 photo" src="../../assets/images/staff/4.jpg"
                            alt="">
                        <div class="comment-text">
                            <h6 style="font-size: 15px;" class="author m-t0">
                                Miss. Madhuri S. Dhande
                            </h6>
                            <div class="w3-separator bg-primary"></div>
                            <div class="description m-b15">
                                <p>
                                    Clinical Instructor
                                </p>
                            </div>
                            <div class="clearfix m-b20">
                                <a target="_blank" href="../../assets/images/staff/4.pdf"
                                    class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="comment_container"> <img class="avatar avatar-60 photo" src="../../assets/images/staff/5.jpg"
                            alt="">
                        <div class="comment-text">
                            <h6 style="font-size: 15px;" class="author m-t0">
                                Mr. John D. Nile
                            </h6>
                            <div class="w3-separator bg-primary"></div>
                            <div class="description m-b15">
                                <p>
                                    Clinical Instructor
                                </p>
                            </div>
                            <div class="clearfix m-b20">
                                <a target="_blank" href="../../assets/images/staff/5.pdf"
                                    class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="comment_container"> <img class="avatar avatar-60 photo" src="../../assets/images/staff/6.jpg"
                            alt="">
                        <div class="comment-text">
                            <h6 style="font-size: 15px;" class="author m-t0">
                                Miss. Shobha S. Ukey
                            </h6>
                            <div class="w3-separator bg-primary"></div>
                            <div class="description m-b15">
                                <p>
                                    Clinical Instructor
                                </p>
                            </div>
                            <div class="clearfix m-b20">
                                <a target="_blank" href="../../assets/images/staff/6.pdf"
                                    class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                            </div>
                        </div>
                    </div>
                </li>
            </ol>
        </div>
    </div>
</div>