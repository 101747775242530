import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  skills: any = {
    title: "P. R. Pote Patil",
    sub_title: "College of Nursing, Amravati",
    content: " <p class='text-justify'>P. R. Pote Patil college of Nursing was established in the year 2023 under the Visionary Leadership of the Ex. State Minister of Maharashtra, Guardian Minister Amravati Hon. Shri. Pravinkumar R. Pote Patil (Chairman), Dynamism of Hon. Shri. Shreyas Pravinkumar Pote Patil (Vice Chairman) &amp; Idealistic Prof. Dr. Sudnyan Gawai (Principal) has steered its iconic status;</p> <p class='text-justify'>The College is offering Bachelor in Nursing.</p> <p class='text-justify'>The College has started with theme <strong class='text-success'>'Quality Education is our Zeal'</strong> keeping the aim to enhance the quality education in the field of Nursing and empower the quality Educators so that they serve the society to develop lifesaving treatment.<br><br> Right from its establishment the institute has maintained the quality in Education to prove the theme; the College has all the Facility, well equipped Laboratory, ample of books in the Library as per the standard Laid down by the regulatory authorities.</p> ",
    content_link: "javascript:void(0)",
    // exp_content: "<h2 class='no-title'>Vision</h2><h4 class='title'>Healty</h4>",
    images_data: {
      "img1": "assets/images/about/about2/college-gate.jpg",
      "img2": "assets/images/about/about2/himani-photo.jpg"
    },
  };




  constructor() {
  }

  ngOnInit(): void {
  }

}
