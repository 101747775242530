import { Component, OnInit } from '@angular/core';
import data from './photo.json'

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  arrPhoto: any = data

  banner: any = {
    pagetitle: "Photo Gallery",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Photo Gallery",
  }

  constructor() { }

  ngOnInit(): void {
  }

}
