import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faculty',
  templateUrl: './faculty.component.html',
  styleUrls: ['./faculty.component.css']
})
export class FacultyComponent implements OnInit {

  banner: any = {
    pagetitle: "Teaching Staff",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Teaching Staff",
  }

  constructor() { }

  ngOnInit(): void {
  }

}
