import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-college',
  templateUrl: './about-college.component.html',
  styleUrls: ['./about-college.component.css']
})
export class AboutCollegeComponent implements OnInit {
  banner: any = {
    pagetitle: "about college",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "About College",
  }

  skills: any = {
    title: "P. R. Pote Patil",
    sub_title: "College of Nursing, Amravati",
    content: " <p class='text-justify'>P. R. Pote Patil college of Nursing was established in the year 2017 under the Visionary Leadership of the Ex. State Minister of Maharashtra, Guardian Minister Amravati Hon. Shri. Pravinkumar R. Pote Patil (Chairman), Dynamism of Hon. Shri. Shreyas Pravinkumar Pote Patil (Vice Chairman) &amp; Idealistic Dr. Dipti B. Ruikar (Principal) has steered its iconic status;</p> <p class='text-justify'>The College is offering Diploma in Nursing &amp; Bachelor in Nursing with the intake capacity of 60 &amp; 100 respectively.</p> <p class='text-justify'>The College has started with theme <strong class='text-success'>'Quality Education is our Zeal'</strong> keeping the aim to enhance the quality education in the field of Nursing and empower the quality pharmacist so that they serve the society to develop lifesaving drugs.<br><br> Right from its establishment the institute has maintained the quality in Education to prove the theme; the College has all the Facility, well equipped Laboratory, ample of books in the Library as per the standard Laid down by the regulatory authorities. </p> ",
    // <p>The institute is approved and affiliated by,</p> <br><ul class='ul_text'> <li>Pharmacy Council of India, New Delhi </li> <li>All India Council for Technical Education, New Delhi </li> <li>Director of Technical Education, Mumbai</li> <li>Sant Gadgebaba Amravati University, Amravati</li> <li>Maharashtra State Board of Technical Education</li> </ul>
    content_link: "javascript:void(0)",
    exp_content: "<h2 class='no-title'>7+</h2><h4 class='title'>Years of Achievements</h4>",
    images_data: {
      "img1": "assets/images/about/about2/college-gate.jpg",
      "img2": "assets/images/about/about2/himani-photo.jpg"
    },
  };

  constructor() { }

  ngOnInit(): void {
  }

}
