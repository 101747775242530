<style>
    @import url('https://fonts.cdnfonts.com/css/rockwell-nova');
</style>

<header class="site-header mo-left header ext-header navstyle1">
    <!-- style="z-index: 1" -->
    <div class="middle-bar bg-white" style="padding: 10px 0">
        <div class="container ">
            <!-- website logo  -->
            <div class="middle-area">
                <div class="logo-header logo-dark">
                    <a [routerLink]="['/']"><img src="../../assets/images/prp_nursing_logo.png" style="width: 100px"
                            alt=""></a>
                </div>
                <div class="service-list"
                    style="line-height: 2.2rem !important; padding-left: 1rem; border-left: 5px solid #F24C3D;">
                    <div style="text-align: left;">
                        <!-- #a83e20 -->
                        <div
                            style="color: #F24C3D; font-size: 2.7rem; font-weight: 500;letter-spacing: 1px;font-family: Rockwell !important;">
                            P. R. Pote Patil </div>
                        <div
                            style="color: #001b5c; font-size: 32px; font-weight: 600;letter-spacing: 1px;font-family: Rockwell !important;">
                            College of
                            Nursing, Amravati</div>
                        <div style="font-size: 15px; font-weight: 600;line-height: 1.7rem;font-family: Rockwell;color: black;
                            font-weight: 600;">
                            Affiliated to Maharashtra University of Health Sciences, Nashik
                        </div>
                        <div style="padding: 1rem 3rem 0 0;">
                            <h6 class="bot-bizo heart"><i>"Nurses for Future, Future for Nurses"</i></h6>
                        </div>
                        <!-- <div  style="font-size: 12px; font-weight: 500;"> Certified by ISO 9001
                            &amp; ISO 14001 </div> -->
                    </div>
                </div>
                <div class="logo-header logo-dark" style="display: flex;justify-content: right;align-items: center;">
                    <!-- <img src="assets/images/pharm.png" style="width: 160px" alt=""> -->
                </div>
            </div>
        </div>
    </div>
    <!-- main header -->
    <div class="sticky-header main-bar-wraper navbar-expand-lg">
        <div class="main-bar clearfix ">
            <div class="container container_new clearfix">
                <!-- website logo -->
                <div class="logo-header mostion logo-dark">
                    <a [routerLink]="['/home-university']"><img src="assets/images/prp_logo.jpeg" style="width: 3rem;"
                            alt=""></a>
                </div>
                <!-- nav toggle button -->
                <button class="navbar-toggler collapsed navicon justify-content-end" type="button"
                    data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div class="header-nav navbar-collapse collapse justify-content-start" id="navbarNavDropdown">
                    <div class="logo-header d-md-block d-lg-none">
                        <a [routerLink]="['/']"><img src="assets/images/logo-2.png" alt=""></a>
                    </div>
                    <ul class="nav navbar-nav">
                        <li class="active has-mega-menu homedemo">
                            <a routerLink="/">Home</a>
                        </li>
                        <li class="has-mega-menu">
                            <a>About Us</a>
                        </li>
                        <li class="has-mega-menu">
                            <a routerLink="/admission/fees-structure">Admission</a>
                        </li>
                        <!-- <li class="has-mega-menu">
                            <a>Departments</a>
                        </li>
                        <li class="has-mega-menu">
                            <a routerLink="/faculty">Facilities</a>
                        </li>-->
                        <li class="has-mega-menu"> 
                            <a routerLink="/faculty">Teaching Staff</a>
                        </li>
                        <li class="has-mega-menu">
                            <a routerLink="/gallery">Gallery / Events</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">MUHS Mandidate <i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu tab-content">
                                <li><a target="_blank" href="../../assets/pdf/mandate/1.pdf">Annexure I</a></li>
                                <li><a target="_blank" href="../../assets/pdf/mandate/2.pdf">Annexure II</a></li>
                                <li><a target="_blank" href="../../assets/pdf/mandate/3.pdf">Annexure III</a></li>
                                <li><a target="_blank" href="../../assets/pdf/mandate/4.pdf">Annexure IV</a></li>
                                <li><a target="_blank" href="../../assets/pdf/mandate/5.pdf">Annexure V</a></li>
                                <li><a target="_blank" href="../../assets/pdf/mandate/6.pdf">Annexure VI</a></li>
                                <li><a target="_blank" href="../../assets/pdf/mandate/7.pdf">Annexure VII</a></li>
                                <li><a target="_blank" href="../../assets/pdf/mandate/8.pdf">Annexure VIII</a></li>
                                <li><a target="_blank" href="../../assets/pdf/mandate/9.pdf">Annexure IX</a></li>
                                <li><a target="_blank" href="../../assets/pdf/mandate/10.pdf">Annexure X</a></li>
                                <li><a target="_blank" href="../../assets/pdf/mandate/11.pdf">Annexure XI</a></li>
                                <li><a target="_blank" href="../../assets/pdf/mandate/12.pdf">Annexure XII</a></li>
                                <li><a target="_blank" href="../../assets/pdf/mandate/13.pdf">Annexure XIII</a></li>
                                <li><a target="_blank" href="../../assets/pdf/mandate/14.pdf">Annexure XIV</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:void(0);">Student Welfare<i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu tab-content">
                                <!-- <li><a>Committees</a></li>
                                <li><a>RTI</a></li> -->
                                <li><a [routerLink]="['/activities/techelons']">Tachelons</a></li>
                                <li><a [routerLink]="['/activities/foreign-tour']">Foreign Tour</a></li>
                                <li><a target="_blank">Parents Meet </a></li>
                                <li><a [routerLink]="['/activities/festival-celebration']">Festival Celebration</a></li>
                                <li><a [routerLink]="['/activities/counselling-cell']">Counselling Cell</a></li>
                                <!-- <li>
                                    <a>R & D</a>
                                </li>
                                <li><a [routerLink]="['/activities/youth-festival']">Youth Festival</a></li>
                                <li><a>NSS</a></li> -->
                                <li><a [routerLink]="['/activities/media-watch']">Media News</a></li>
                                <li><a>Women's Grievance Cell</a></li>
                            </ul>
                        </li>
                        <!-- <li class="has-mega-menu">
                            <a>Achievements</a>
                        </li>
                        <li class="has-mega-menu">
                            <a>Academic</a>
                        </li>
                        <li class="has-mega-menu">
                            <a>Research</a>
                        </li>
                        <li class="has-mega-menu">
                            <a>NIRF</a>
                        </li>
                        <li class="has-mega-menu">
                            <a>Blog</a>
                        </li> -->
                        <li class="has-mega-menu">
                            <a>Contact</a>
                        </li>
                    </ul>
                    <div class="dlab-social-icon">
                        <ul>
                            <li><a class="site-button facebook sharp-sm outline fa fa-facebook"
                                    href="javascript:void(0);"></a></li>
                            <li><a class="site-button twitter sharp-sm outline fa fa-twitter"
                                    href="javascript:void(0);"></a></li>
                            <li><a class="site-button linkedin sharp-sm outline fa fa-linkedin"
                                    href="javascript:void(0);"></a></li>
                            <li><a class="site-button instagram sharp-sm outline fa fa-instagram"
                                    href="javascript:void(0);"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- main header END -->
</header>