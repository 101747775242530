import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { HomeSliderComponent } from './home/home-slider/home-slider.component';
import { HomeAboutComponent } from './home/home-about/home-about.component';
import { HomeCoursesComponent } from './home/home-courses/home-courses.component';
import { HomeCounterComponent } from './home/home-counter/home-counter.component';
import { HomeTeamComponent } from './home/home-team/home-team.component';
import { HomeEminientComponent } from './home/home-eminient/home-eminient.component';
import { HomeClientComponent } from './home/home-client/home-client.component';
import { HomeTestimonialComponent } from './home/home-testimonial/home-testimonial.component';
import { Slider2Component } from './elements/slider2/slider2.component';
import { AboutCollegeComponent } from './about-college/about-college.component';
import { AboutGoverningBodyComponent } from './about-governing-body/about-governing-body.component';
import { CollegeDevelopmentCommitteeComponent } from './college-development-committee/college-development-committee.component';
import { CollegeOrgnanisationChartComponent } from './college-orgnanisation-chart/college-orgnanisation-chart.component';
import { AboutMessagesComponent } from './about-college/about-messages/about-messages.component';
import { HeaderBannerComponent } from './header-banner/header-banner.component';
import { RdCellComponent } from './rd-cell/rd-cell.component';
import { ContactComponent } from './contact/contact.component';
import { IqacOldComponent } from './iqac-old/iqac-old.component';
import { NaacSsrComponent } from './naac-ssr/naac-ssr.component';
import 'hammerjs';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { HomeYoutubeComponent } from './home/home-youtube/home-youtube.component';
import { YoutubeChannelComponent } from './youtube-channel/youtube-channel.component';
import { ActivityDetailsComponent } from './department/activity-details/activity-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatDialogModule} from '@angular/material/dialog';
import { LightboxModule } from 'ngx-lightbox';
import {HttpClientModule} from '@angular/common/http';
import { BestPracticeComponent } from './best-practice/best-practice.component';
import { PosterComponent } from './home/poster/poster.component';
import { HomeMentorComponent } from './home/home-mentor/home-mentor.component';
import { HomePrincipalComponent } from './home/home-principal/home-principal.component';
import { HomeVisionMissionComponent } from './home/home-vision-mission/home-vision-mission.component';
import { WorkshopComponent } from './workshop/workshop.component';
import { HomeAboutMgtComponent } from './home/home-about-mgt/home-about-mgt.component';
import { RulesOfConductComponent } from './rules-of-conduct/rules-of-conduct.component';
import { SalientFeaturesComponent } from './salient-features/salient-features.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { AboutDetailsComponent } from './about-college/about-details/about-details.component';
import { AboutPoComponent } from './about-college/about-po/about-po.component';
import { AboutInfraComponent } from './about-college/about-infra/about-infra.component';
import { RAndDComponent } from './r-and-d/r-and-d/r-and-d.component';
import { PublicationFacultyComponent } from './r-and-d/publication-faculty/publication-faculty.component';
import { PublicationStudentComponent } from './r-and-d/publication-student/publication-student.component';
import { PatentsComponent } from './r-and-d/patents/patents.component';
import { OralPresentationComponent } from './r-and-d/oral-presentation/oral-presentation.component';
import { AwardsPresentationComponent } from './r-and-d/awards-presentation/awards-presentation.component';
import { AwardsAccoladesComponent } from './r-and-d/awards-accolades/awards-accolades.component';
import { StudentCouncilComponent } from './student/student-council/student-council.component';
import { GrcComponent } from './student/grc/grc.component';
import { IccComponent } from './student/icc/icc.component';
import { AntiDiscComponent } from './student/anti-disc/anti-disc.component';
import { ScStComponent } from './student/sc-st/sc-st.component';
import { AntiRaggingComponent } from './student/anti-ragging/anti-ragging.component';
import { StudentEnrolledComponent } from './student/student-enrolled/student-enrolled.component';
import { AlumniAssociationComponent } from './student/alumni/alumni-association/alumni-association.component';
import { AlumniDpharmComponent } from './student/alumni/alumni-dpharm/alumni-dpharm.component';
import { AlumniBpharmComponent } from './student/alumni/alumni-bpharm/alumni-bpharm.component';
import { OutstandingAlumniComponent } from './student/alumni/outstanding-alumni/outstanding-alumni.component';
import { AlumniMeetComponent } from './student/alumni/alumni-meet/alumni-meet.component';
import { EventsComponent } from './events/events.component';
import { SportsParticipationComponent } from './sports/sports-participation/sports-participation.component';
import { SportsAwardsComponent } from './sports/sports-awards/sports-awards.component';
import { BuletinComponent } from './buletin/buletin.component';
import { UpcomingEventsComponent } from './home/upcoming-events/upcoming-events.component';
import { FacultyComponent } from './faculty/faculty.component';
import { GalleryComponent } from './gallery/gallery.component';
// import { NgImageSliderModule } from 'ng-image-slider';


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        HomeSliderComponent,
        HomeAboutComponent,
        HomeCoursesComponent,
        HomeCounterComponent,
        HomeTeamComponent,
        HomeEminientComponent,
        HomeClientComponent,
        HomeTestimonialComponent,
        Slider2Component,
        AboutCollegeComponent,
        AboutGoverningBodyComponent,
        CollegeDevelopmentCommitteeComponent,
        CollegeOrgnanisationChartComponent,
        AboutMessagesComponent,
        HeaderBannerComponent,
        RdCellComponent,
        ContactComponent,
        IqacOldComponent,
        NaacSsrComponent,
        HomeYoutubeComponent,
        YoutubeChannelComponent,
        ActivityDetailsComponent,
        BestPracticeComponent,
        PosterComponent,
        HomeMentorComponent,
        HomePrincipalComponent,
        HomeVisionMissionComponent,
        WorkshopComponent,
        HomeAboutMgtComponent,
        RulesOfConductComponent,
        SalientFeaturesComponent,
        ApprovalsComponent,
        AboutDetailsComponent,
        AboutPoComponent,
        AboutInfraComponent,
        RAndDComponent,
        PublicationFacultyComponent,
        PublicationStudentComponent,
        PatentsComponent,
        OralPresentationComponent,
        AwardsPresentationComponent,
        AwardsAccoladesComponent,
        StudentCouncilComponent,
        GrcComponent,
        IccComponent,
        AntiDiscComponent,
        ScStComponent,
        AntiRaggingComponent,
        StudentEnrolledComponent,
        AlumniAssociationComponent,
        AlumniDpharmComponent,
        AlumniBpharmComponent,
        OutstandingAlumniComponent,
        AlumniMeetComponent,
        EventsComponent,
        SportsParticipationComponent,
        SportsAwardsComponent,
        BuletinComponent,
        UpcomingEventsComponent,
        FacultyComponent,
        GalleryComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        NgxGalleryModule,
        BrowserAnimationsModule,
        MatDialogModule,
        LightboxModule,
        HttpClientModule,
        // NgImageSliderModule,
        
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
