<div class="section-full content-inner bg-white">
    <div class="container">
        <div class="section-head m-b20">
            <h2 class="title" style="color: #F24C3D;font-family: Rockwell !important;font-weight: 500;">Our Vision /
                Mission / Philosophy</h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>
        <div class="vision-mission" style="margin-bottom: 2rem;">
            <div
                style="border: 1px solid rgba(128, 128, 128, 0.24);padding: 0 !important;background: #fff;box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .05) !important;border-radius: 0 70px 0 70px;margin-right: 5px;">
                <div
                    style="color: #fff;padding: 10px;background-color: #0e2954;font-size: 17px;font-weight: 600;text-transform: uppercase;text-align: center;    border-radius: 0 70px 0 0;">
                    Our Vision
                </div>
                <div
                    style="color: #000;padding: 10px 20px;font-size: 14px;font-weight: 600;line-height: 1.7rem;text-align: justify;">
                    Hon’ble P. R. Pote Patil Sir had laid a firm foundation for dissemination of education among the
                    downtrodden regions.
                    He knew that prosperity would come only when the weapon of knowledge became available to all.
                    Inspired by a vision of founder president, we use nursing as a weapon of knowledge in terms of
                    quality and excellence to educate young girls and boys especially from the areas of poor and
                    backward section of the communities.
                </div>
            </div>

            <div
                style="border: 1px solid rgba(128, 128, 128, 0.24);padding: 0 !important;background: #fff;box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .05) !important;border-radius: 0 70px 0 70px;margin-right: 5px;">
                <div
                    style="color: #fff;padding: 10px;background-color: #0e2954;font-size: 17px;font-weight: 600;text-transform: uppercase;text-align: center;    border-radius: 0 70px 0 0;">
                    Our Mission
                </div>
                <div style="color: #000;padding: 10px 20px;font-size: 14px;font-weight: 600;">
                    <ul style="margin-left: 1rem;line-height: 1.7rem;">
                        <li>
                            Encouraging young girls and boys especially from the areas of poor and backward section for
                            nursing profession as it has wider in scope
                        </li>
                        <li>
                            Strengthening the arms and mind of nursing students by creating an atmosphere for achieving
                            excellence in nursing education though skilled teaching, supervised clinical training and
                            state of the art technologies.
                        </li>
                    </ul>
                </div>
            </div>

            <div style="border: 1px solid rgba(128, 128, 128, 0.24);padding: 0 !important;background: #fff;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .05) !important;border-radius: 0 70px 0 70px;margin-right: 5px;">
                <div
                    style="color: #fff;padding: 10px;background-color: #0e2954;font-size: 17px;font-weight: 600;text-transform: uppercase;text-align: center;    border-radius: 0 70px 0 0;">
                    Our Philosophy
                </div>
                <div style="color: #000;padding: 10px 2rem;font-size: 14px;font-weight: 600;">
                    <ul style="text-align: justify;">
                        <li>Believe that the domains of nursing education is built on scientific principles, sound
                            educational theory and practice in order to prepare professionally qualified and challenging
                            nurses, who will be able to function as a team member in health care agencies, and also believe
                            that the nurses those who are experienced can move in to leadership positions.</li>
    
                        <li>believe that the nursing education is all round development of students with special emphasis on
                            intellectual, technical, cultural, moral and spiritual aspects that are required to prepare a
                            professionally competent nurse who shall be a productive citizen and an effective change agent
                            in our ever changing health needs of the society.</li>
    
                        <li>Recognize student those who have potential and motivation to become nurse educators, nurse
                            administrators, clinical specialist and researchers on par with the obstinate demands of the
                            society, who will be able to contribute towards profession by meeting the issues and challenges
                            confronted by the nursing profession today.</li>
    
                        <li>Accept the need of periodic evaluation of nursing curriculum and making the necessary changes in
                            both theory and practical as the profession is influenced by rapid advance in biotechnology and
                            ever changing health care delivery system.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>