<div class="section-full content-inner bg-white">
    <div class="container">
        <!-- align-items-center -->
        <div class="row ">
            <div class="col-lg-4 col-md-12 m-b30 about-two align-self-start">
                <div class="row sp10">
                    <div class="col-md-12 m-b10">
                        <img src="{{data.images_data.img1}}" class="img-cover" alt="">
                    </div>
                    <div class="col-md-7 m-b10">
                        <img src="{{data.images_data.img2}}" class="img-cover" alt="">
                    </div>
                    <div class="col-md-5 m-b10">
                        <div class="about-year bg-primary">
                            <div [innerHTML]="data.exp_content" style="text-align: center;"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 m-b30 about-two">
                <div class="section-head m-b20">
                    <h2 class="title" style="color: #F24C3D;font-family: Rockwell !important;font-weight: 500;">
                        {{data.title}}</h2>
                    <h3 class="title-small"
                        style="font-size: 22px;font-weight: 500;color: #001b5c;font-family: Rockwell !important;">
                        {{data.sub_title}}</h3>
                    <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
                </div>

                <div style="font-size: 15px;font-weight: 500;" [innerHTML]="data.content"></div>

                <!-- <a href="{{data.content_link}}" class="site-button">Read More</a> -->
            </div>
            <div class="col-lg-3" style="border: 1px solid #0e295442;padding: 0 !important; height: 30rem;">
                <div style="background: #0e2954; padding: 10px; text-align: center;color: white;">
                    News & Announcements
                </div>
                <div style="padding: 10px;height: 100% !important;">
                    <marquee behavior="" direction="up" height="400" onmouseover="this.stop();"
                        onmouseout="this.start();">
                       
                        <!-- <div style="width: 100%; margin: 10px; background: #eeac59; padding: 10px;">
                            <a href="https://drive.google.com/drive/folders/1zDHaJ3qrgFLGCQbks8S9kiNbTcEvJyrN?usp=sharing"
                                target="_blank">
                                Offer Letter Distribution 2023 &nbsp; <i class="fa fa-mouse-pointer"></i>
                            </a>
                        </div> -->
                    </marquee>
                </div>
            </div>
        </div>
    </div>
</div>